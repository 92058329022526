import { Route, Routes } from "react-router-dom";
import "./Fontstyle.css"
import Login from "./Login/Login";
import Home from "./Home";
import Customer_login from "./Main_content/Customer_content/Customer_login";
import Customer_Password_change from "./Main_content/Customer_content/Customer_Password_change";
import Customer from "./Main_content/Customer_content/Customer";
import SSO_page from "./SSO_Login/SSO_page"
import SSO_CallBack from "./SSO_Login/SSO_CallBack"

function App() {
  return (
    <div className="container-fluid ps-0 pe-0">
     <Routes>
        <Route path="*" element={<Home />} />       
        <Route path="customer_login" element={<Customer_login />} />
        <Route path="Customer_Password_change" element={<Customer_Password_change />} />
        <Route path="Customer" element={<Customer />} />
        <Route path="/callback" element={<SSO_CallBack />} />
        <Route path="/" element={<SSO_page />} />
      </Routes>
    </div>
  );
}

export default App;
