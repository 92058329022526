import React, { useState } from 'react'
import Header from './Header/Header'
import Main_content from './Main_content/Main_content'


const Home = () => {
    const [toggle, setToggle] = useState(true)
   
    return (
        <div>
            <div className='container-fluid ps-0 pe-0'>
                <Header
                    toggle={toggle}
                    setToggle={setToggle}
                />
                <Main_content
                    toggle={toggle}

                />
            </div>
        </div>
    )
}

export default Home