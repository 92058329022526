
import { Route, Routes, useNavigate } from 'react-router-dom'
import User_master from './User_master'
import VRN_dashboard from './VRN_dashboard'
import VRN_master from './VRN_master'
import { useEffect } from 'react'




const Table_route = () => {
   
    const ErrorRedirect = () => {
        const navigate = useNavigate();

        useEffect(() => {
           // dispatch(setError(true)); // Set error state in Redux
            navigate('/Page_not_found'); // Redirect to a dedicated error route
        }, [navigate]);

        return null;
    };
    return (
        <div>
            <Routes>
                <Route exact path="/VRN_master" element={<VRN_master />} />
                <Route exact path="/VRN_dashboard" element={<VRN_dashboard />} />
                <Route exact path="/User_master" element={<User_master />} />
                <Route path="*" element={<ErrorRedirect  />} />
            </Routes>
        </div >
    )

}
export default Table_route